function App() {
  return (
    <div>
      {/*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', zIndex: 1000, position: 'relative', height: '100vh', padding: '20px' }}>*/}
      {/*  /!*<iframe src="https://rimas.ffm.to/unveranosinti" width="300" height="400" title={"Save"}  style={{borderRadius: '15px', border: '1px solid rgba(255, 255, 255, .2)', overflow: 'hidden'}}/>*!/*/}
      {/*  */}
      {/*</div>*/}
      <div className={'app-wrap'}>
        <div className="text-logo" />
        <div className="bg" />
        <div className='clouds'>
          <div className="bird-1" />
          <div className="bird-2" />
          <div className="bird-3" />
          <div className="bird-1 left" />
          <div className="bird-2 left" />
          <div className="bird-3 left" />
        </div>
        <div className='sand'>
          <div className="tree">
            <div className="leaves" />
          </div>
          <div className="tree right">
            <div className="leaves" />
          </div>
        </div>
        <div className="flowers">
          <div className="petals-1" />
          <div className="petals-2" />
        </div>
        <div className="flowers reverse">
          <div className="petals-1" />
          <div className="petals-2" />
        </div>
        <div className="heart-path">
          <div className="heart-wrapper">
            <div className="heart" />
          </div>
        </div>
        <div className='ocean-wrapper'>
          <div className='ocean'>
            <div className="reflection stage-1" />
            <div className="reflection stage-2" />
            <div className="reflection stage-3" />
            <div className='waves' />
          </div>
        </div>
        <div className="dolphin-track">
          <div className="dolphin" />
        </div>
        <div className="dolphin-track alt">
          <div className="dolphin" />
        </div>

        <div className="sun-wrapper">
          <div className="sun" />
        </div>
      </div>
    </div>
  );
}

export default App;
